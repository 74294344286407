import 'custom-event-polyfill';
import scrollToElement from '../../dom/scroll-to-element';
import $ from 'bianco.query';
import { add } from 'bianco.events';
import { loadImages } from 'bianco.images-loader';

const { hash } = window.location;
const waitWindowLoaded = () => new Promise(resolve => add(window, 'load', resolve));
const loadAllImages = () => loadImages($('img'));
const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
const scrollToHashedElement = () => scrollToElement(hash);
const dispatchAnchorLinkReadyEvent = () => {
    globals.state.anchorLinkReady = true;
    window.dispatchEvent(new CustomEvent('anchor-link-ready'));
};

if (hash) {
    // fix the anchor scrolling when the site has been loaded and all the site images are finally available
    waitWindowLoaded()
        .then(loadAllImages)
        .then(() => wait(500))
        .then(scrollToHashedElement)
        .then(() => wait(500))
        .then(dispatchAnchorLinkReadyEvent);
} else {
    dispatchAnchorLinkReadyEvent();
}
