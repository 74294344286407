import $ from 'bianco.query';

/**
 * Scroll to a DOM node
 * @param {string} selector - element selector
 * @return {HTMLElement|unknown} the first element matching the selector
 */
export default function scrollToElement(selector) {
    try {
        const [element] = $(selector);

        if (element) {
            element.scrollIntoView();
        }

        return element;
    } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(error);
        }

        return null;
    }
}
